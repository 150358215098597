import { AuthService } from "./auth.service";
import { ApiRootConstants } from "../_constants/index";
import axios from "axios";
import { NIL as NIL_UUID } from "uuid";
import devconsole from "../components/_common/devconsole";

const authService = new AuthService();

const mapDashboard = item => ({
  id: item.dashboardId,
  name: item.name,
  venueModeId: item.venueModeId,
  venueModeName: item.venueModeName,
  venueId: item.venueId,
  description: item.description,
  dashboardWidget: item.dashboardWidget,
  publishedUrl: item.publishedURL,
  publishedDate: item.publishedDate,
  lastUpdated: item.lastUpdated,
  lastUpdatedByUserId: item.lastUpdatedByUserId,
  lastUpdatedByUser: item.lastUpdatedByUser,
  isProtected: item.isProtected,
  isActive: item.isActive,
});
 
async function axiosGet(uri) {
  const user = await authService.getUser();
  console.log("user", user);
  if (user && user.access_token) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + user.access_token,
    };

    return await axios
      .get(uri, { headers })
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        if (error.response.status === 401) {
          throw new Error("User is not logged in");
        }
        throw error;
      });
  }
}

async function getDashboardsList(venueId, isSimple, isPublished) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + user.access_token,
    };
    let isPublishedParam = "";
    if (isPublished !== null) {
      isPublishedParam = "?isPublished=" + isPublished;
    }

    const simpleParam = isSimple ? '/SimpleList' : '';

    return await axios
      .get(`${ApiRootConstants.dashboardApiRoot}api/Dashboard${simpleParam}/${venueId}${isPublishedParam}`, {
        headers,
      })
      .then(response => response.data.map(mapDashboard))
      .catch(error => {
        console.error(error);
        if (error.response.status === 401) {
          throw new Error("User is not logged in");
        }
        throw error;
      });
  }
}

export function getDashboards(venueId, isPublished) {
  return getDashboardsList(venueId, false, isPublished);
}

export function getDashboardsSimple(venueId, isPublished) {
  return getDashboardsList(venueId, true, isPublished);
}

export async function getDefaultDashboard() {
  const user = await authService.getUser();
  let userId = user?.profile.sub;
  if (user && user.access_token) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + user.access_token,
    };

    return await axios
      .get(`${ApiRootConstants.dashboardApiRoot}api/Dashboard/GetDefaultDashboard/${userId}`, {
        headers,
      })
      .then((response) => {

        let data = {
          userRole: user.profile.role,
          systemDashboard: response.data.systemDefaultDashboardId,
          userDashboard: response.data.userDefaultDashboardId,
          venueId: response.data.venueId,
        }

        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          throw new Error("User is not logged in");
        }
        throw error.response.data.message;
      });
  }
}


export async function getWidgetTrafficTypes() {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + user.access_token,
    };

    return await axios
      .get(`${ApiRootConstants.dashboardApiRoot}api/WidgetTrafficType`, {
        headers,
      })
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        if (error.response.status === 401) {
          throw new Error("User is not logged in");
        }
        throw error.response.data.message;
      });
  }
}

export async function getDashboard(venueId, dashboardId) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + user.access_token,
    };

    return await axios
      .get(`${ApiRootConstants.dashboardApiRoot}api/Dashboard/${venueId}/${dashboardId}`, {
        headers,
      })
      .then(response => mapDashboard(response.data))
      .catch(error => {
        console.error(error);
        if (error.response.status === 401) {
          throw new Error("User is not logged in");
        }
        throw error;
      });
  }
}
export async function addDashboard(dashboard) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };
    return await axios
      .post(
        `${ApiRootConstants.dashboardApiRoot}api/Dashboard`,
        dashboard,
        options
      )
      .then(response => mapDashboard(response.data))
      .catch((error) => {
        console.error(error);
        if (error.response.status === 401) {
          throw new Error("User is not logged in");
        } else if (error.response.status === 422) {
          throw new Error(error.response.data.message);
        }

        throw error;
      });
  }
}

export async function updateDashboard(dashboard) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };

    return await axios
      .put(
        `${ApiRootConstants.dashboardApiRoot}api/Dashboard`,
        {
          dashboardId: dashboard.id,
          name: dashboard.name,
          venueModeId: dashboard.venueModeId,
          venueId: dashboard.venueId,
          description: dashboard.description,
          isProtected: dashboard.isProtected
        },
        options
      )
      .catch((error) => {
        console.error(error);
        if (error.response.status === 401) {
          throw new Error("User is not logged in");
        } else if (error.response.status === 422) {
          throw new Error(error.response.data.message);
        }

        throw error;
      });
  }
}

export async function removeDashboard(dashboardId) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };

    return await axios
      .delete(
        `${ApiRootConstants.dashboardApiRoot}api/Dashboard/${dashboardId}`,
        options
      )
      .catch((error) => {
        console.error(error);
        if (error.response.status === 401) {
          throw new Error("User is not logged in");
        } else
          throw new Error(error.response.data.message)
      });
  }
}

export async function removeDashboardRange(dashboardIds) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };

    return await axios
      .put(
        `${ApiRootConstants.dashboardApiRoot}api/Dashboard/DeleteRange`,
        dashboardIds,
        options
      )
      .catch((error) => {
        console.error(error);
        if (error.response.status === 401) {
          throw new Error("User is not logged in");
        } else if (error.response.data.message) {
          throw new Error(error.response.data.message);
        } else {
          throw new Error(error);
        }
      });
  }
}

export async function addWidgets(dashboardId, widgets) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };

    return await axios
      .post(
        `${ApiRootConstants.dashboardApiRoot}api/Dashboard/${dashboardId}/dashboardWidgets`,
        widgets,
        options
      )
      .catch((error) => {
        console.error(error);
        if (error.response.status === 401) {
          throw new Error("User is not logged in");
        } else if (error.response.status === 422) {
          throw new Error(error.response.data.message);
        }

        throw error;
      });
  }
}

export async function updateWidgetsPosition(widgets) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };

    const dimensions = widgets.map(w => w.dimensions).flat();

    return await axios
      .put(
        `${ApiRootConstants.dashboardApiRoot}api/DashboardWidget/UpdatePositions`,
        dimensions,
        options
      )
      .catch((error) => {
        console.error(error);
        if (error.response.status === 401) {
          throw new Error("User is not logged in");
        } else if (error.response.status === 422) {
          throw new Error(error.response.data.message);
        }

        throw error;
      });
  }
}

export async function deleteWidget(widgetId) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };

    return await axios
      .delete(
        `${ApiRootConstants.dashboardApiRoot}api/DashboardWidget/${widgetId}`,
        options
      )
      .catch((error) => {
        console.error(error);
        if (error.response.status === 401) {
          throw new Error("User is not logged in");
        } else if (error.response.status === 422) {
          throw new Error(error.response.data.message);
        }

        throw error;
      });
  }
}

export async function duplicateDashboards(venueId, locationIds, isPublished) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };
    var postData = locationIds;

    return await axios
      .post(
        `${ApiRootConstants.dashboardApiRoot}api/Dashboard/DuplicateDashboards/${venueId}?isPublished=${isPublished}`,
        postData,
        options
      )
      .catch((error) => {
        console.error(error);
        if (error.response.status === 401) {
          throw new Error("User is not logged in");
        } else if (error.response.status === 422) {
          throw new Error(error.response.data.message);
        }

        throw error;
      });
  }
}

export async function publishDashboardJSONConfig(venueId, locationIds) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };
    var postData = locationIds;

    return await axios
      .post(
        `${ApiRootConstants.dashboardApiRoot}api/Dashboard/PublishDashboards/${venueId}`,
        postData,
        options
      )
      .catch((error) => {
        console.error(error);
        if (error.response.status === 401) {
          throw new Error("User is not logged in");
        } else if (error.response.status === 422) {
          throw new Error(error.response.data.message);
        }

        throw error;
      });
  }
}


export async function unpublishDashboardJSONConfig(venueId, locationIds) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };
    var postData = locationIds;

    return await axios
      .post(
        `${ApiRootConstants.dashboardApiRoot}api/Dashboard/UnpublishDashboards/${venueId}`,
        postData,
        options
      )
      .catch((error) => {
        console.error(error);
        if (error.response.status === 401) {
          throw new Error("User is not logged in");
        } else if (error.response.status === 422) {
          throw new Error(error.response.data.message);
        }

        throw error;
      });
  }
}

export async function updateWidgetSettingValue(widgetSettingValues) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };
    return await axios
      .put(
        `${ApiRootConstants.dashboardApiRoot}api/UpdateWidgetSettingValue`,
        widgetSettingValues.filter(e => e.value !== null).map(widgetSettingValue => {
          return {
            Id: NIL_UUID,
            DashboardWidgetId: widgetSettingValue.dashboardWidgetId,
            WidgetSettingId: widgetSettingValue.widgetSettingId,
            Value: widgetSettingValue.value,
          }
        }),
        options
      )
      .catch((error) => {
        console.error(error);
        if (error.response.status === 401) {
          throw new Error("User is not logged in");
        } else if (error.response.status === 422) {
          throw new Error(error.response.data.message);
        }

        throw error;
      });
  }
}

export async function updateRefListWidgetSettingValue(refIdListSettingWithValues) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };
    return await axios
      .put(
        `${ApiRootConstants.dashboardApiRoot}api/UpdateRefIdListWidgetSettingValue/${refIdListSettingWithValues.dashboardWidgetId}/${refIdListSettingWithValues.widgetSettingId}`,
        refIdListSettingWithValues.value.map(value => {
          return {
            Id: NIL_UUID,
            DashboardWidgetId: refIdListSettingWithValues.dashboardWidgetId,
            WidgetSettingId: refIdListSettingWithValues.widgetSettingId,
            Value: value,
          }
        }),
        options
      )
      .catch((error) => {
        console.error(error);
        if (error.response.status === 401) {
          throw new Error("User is not logged in");
        } else if (error.response.status === 422) {
          throw new Error(error.response.data.message);
        }

        throw error;
      });
  }
}

export async function updateRefTableWidgetSettingValue(refTableSettingWithValues) {
  let rowNumber = 0;

  var dataToUpdate = [];
  refTableSettingWithValues.value.forEach(mainAndSelectiveEntities => {
    Object.keys(mainAndSelectiveEntities).forEach(key => {
      dataToUpdate.push({
        value: mainAndSelectiveEntities[key].value,
        widgetSettingsValueColumn: {
          WidgetSettingsTableColumnId: mainAndSelectiveEntities[key].widgetSettingsTableColumnId,
          RowNumber: rowNumber
        }
      });
    })

    rowNumber++;
  });

  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };

    let finalData = dataToUpdate.map(e => {
      return {
        Id: NIL_UUID,
        DashboardWidgetId: refTableSettingWithValues.dashboardWidgetId,
        WidgetSettingId: refTableSettingWithValues.widgetSettingId,
        Value: e.value,
        WidgetSettingsValueColumn: e.widgetSettingsValueColumn,
      }
    });

    return await axios
      .put(
        `${ApiRootConstants.dashboardApiRoot}api/UpdateRefIdListWidgetSettingValue/${refTableSettingWithValues.dashboardWidgetId}/${refTableSettingWithValues.widgetSettingId}`,
        finalData,
        options
      )
      .catch((error) => {
        console.error(error);
        if (error.response.status === 401) {
          throw new Error("User is not logged in");
        } else if (error.response.status === 422) {
          throw new Error(error.response.data.message);
        }

        throw error;
      });
  }
}

export const getPublishedDashboard = async (venueName, dashboardName, token = null) => {
  var userToken = token;
  if (token === null) {
    // when axiosHeader is null then automatically get the header from authentication service
    // and it will create automatically to pass the user token (bearer)
    const user = await authService.getUser();
    if (user && user.access_token) {
      userToken = user.access_token;
    }
  }
  let options = {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + userToken
    },
  };

  const venName = encodeURIComponent(venueName);
  const dbdName = encodeURIComponent(dashboardName);
  const url = `${ApiRootConstants.dashboardApiRoot}api/Dashboard/GetPublishedDashboard/${venName}/${dbdName}`;
  devconsole.log("URL : " + url, token);
  return axios.get(url, options)
    .then(response => response.data.map(mapDashboard))
    .catch((error) => {
      if (error.response.status === 401) {
        console.error(error);
        throw new Error("User is not logged in");
      } else if (error.response.status === 422) {
        throw new Error(error.response.data.message);
      }

      throw error;
    });
}

export async function getWidgetSettings(widgetTypeId) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + user.access_token,
    };

    return await axios
      .get(`${ApiRootConstants.dashboardApiRoot}api/DashboardWidgetSettings/GetWidgetTypeSettings/${widgetTypeId}`, {
        headers,
      })
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        if (error.response.status === 401) {
          throw new Error("User is not logged in");
        }
        throw error;
      });
  }
}

export function getAllWidgetTypesSettings() {
  return axiosGet(`${ApiRootConstants.dashboardApiRoot}api/DashboardWidgetSettings/GetAllWidgetTypesSettings`);
}

export function getWidgetSettingsValues(widgetId) {
  return axiosGet(`${ApiRootConstants.dashboardApiRoot}api/DashboardWidgetSettings/GetWidgetSettingsValues/${widgetId}`);
}

export function getWidgetSettingsCollectionGroups(widgetSettingId) {
  return axiosGet(`${ApiRootConstants.dashboardApiRoot}api/DashboardWidgetSettings/GetWidgetSettingsCollectionGroups/${widgetSettingId}`);
}

export async function loadOccupancyViewModes() {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + user.access_token,
    };

    return axios
      .get(ApiRootConstants.dashboardApiRoot + "api/DashboardWidgetSettings/GetOccupancyViewModes", {
        headers,
      })
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }
        throw error;
      });
  }
}

export async function loadTrafficTypes() {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + user.access_token,
    };

    return axios
      .get(ApiRootConstants.dashboardApiRoot + "api/DashboardWidgetSettings/GetTrafficTypes", {
        headers,
      })
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }
        throw error;
      });
  }
}

export async function loadCohorts() {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + user.access_token,
    };

    return axios
      .get(ApiRootConstants.ARMVendorApiRoot + "api/ARM/GetCohortsList", {
        headers,
      })
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }
        throw error;
      });
  }
}

export async function loadNumberWidgetValueTypes() {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + user.access_token,
    };

    return axios
      .get(ApiRootConstants.dashboardApiRoot + "api/DashboardWidgetSettings/GetNumberWidgetValueTypes", {
        headers,
      })
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }
        throw error;
      });
  }
}

export async function loadDemographicWidgetValueTypes() {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + user.access_token,
    };

    return axios
      .get(ApiRootConstants.dashboardApiRoot + "api/DashboardWidgetSettings/GetDemographicWidgetValueTypes", {
        headers,
      })
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }
        throw error;
      });
  }
}

export async function loadDemographicWidgetTimePeriods() {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + user.access_token,
    };

    return axios
      .get(ApiRootConstants.dashboardApiRoot + "api/DashboardWidgetSettings/GetDemographicWidgetTimePeriods", {
        headers,
      })
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }
        throw error;
      });
  }
}

export async function loadDemographicWidgetGender() {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + user.access_token,
    };

    return axios
      .get(ApiRootConstants.dashboardApiRoot + "api/DashboardWidgetSettings/GetDemographicWidgetGenderList", {
        headers,
      })
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }
        throw error;
      });
  }
}

export async function loadDemographicWidgetAgeGroup() {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + user.access_token,
    };

    return axios
      .get(ApiRootConstants.dashboardApiRoot + "api/DashboardWidgetSettings/GetDemographicWidgetAgeGroup", {
        headers,
      })
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }
        throw error;
      });
  }
}

export async function loadNumberWidgetTimePeriods() {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + user.access_token,
    };

    return axios
      .get(ApiRootConstants.dashboardApiRoot + "api/DashboardWidgetSettings/GetNumberWidgetTimePeriods", {
        headers,
      })
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }
        throw error;
      });
  }
}

export async function loadMapWidgetIndicators() {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + user.access_token,
    };

    return axios
      .get(ApiRootConstants.dashboardApiRoot + "api/DashboardWidgetSettings/GetMapWidgetIndicators", {
        headers,
      })
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }
        throw error;
      });
  }
}

export async function loadDashboardWidgetsAccumulatedData(dashboardId, access_token, clearCache = false) {
  const headers = {
    Accept: "application/json",
    Authorization: "Bearer " + (access_token ?? (await getWidgetDataAccessToken())),
  };

  return (await axios
    .get(`${ApiRootConstants.dashboardApiRoot}api/DashboardWidgetData/WidgetsData/${dashboardId}/${clearCache}`, {
      headers,
    })).data;
}

export async function checkDashboardCache(dashboardId, sessionId, access_token, clearCache = false) {
  const headers = {
    Accept: "application/json",
    Authorization: "Bearer " + (access_token ?? (await getWidgetDataAccessToken())),
  };

  return (await axios
    .get(`${ApiRootConstants.dashboardApiRoot}api/DashboardWidgetData/CheckCache/${dashboardId}/${sessionId}/${clearCache}`, {
      headers,
    })).data;
}

export async function getWidgetDataAccessToken() {
  return await authService.getClientCredentialsToken(
    process.env.REACT_APP_API_CLIENT_ID,
    process.env.REACT_APP_API_CLIENT_SECRET,
    process.env.REACT_APP_API_CLIENT_SCOPE
  );
}

export function loadQueueModelAlertMetrics() {
  return axiosGet(`${ApiRootConstants.dashboardApiRoot}api/DashboardWidgetSettings/GetQueueModelAlertMetrics`);
}

export function loadTrafficInOutAlertMetrics() {
  return axiosGet(`${ApiRootConstants.dashboardApiRoot}api/DashboardWidgetSettings/GetTrafficInOutAlertMetrics`);
}

export function loadTrafficInOutDisplayModes() {
  return axiosGet(`${ApiRootConstants.dashboardApiRoot}api/DashboardWidgetSettings/GetTrafficInOutDisplayModes`);
}

export function loadPatronDistributionMetrics() {
  return axiosGet(`${ApiRootConstants.dashboardApiRoot}api/DashboardWidgetSettings/GetPatronDistributionMetrics`);
}

export function loadPatronDistributionValueTypes() {
  return axiosGet(`${ApiRootConstants.dashboardApiRoot}api/DashboardWidgetSettings/GetPatronDistributionValueTypes`);
}

export async function activateDashboards(dashboardIds) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };

    return await axios
      .post(
        `${ApiRootConstants.dashboardApiRoot}api/Dashboard/ActivateDashboards`,
        dashboardIds,
        options
      )
      .catch((error) => {
        console.error(error);
        if (error.response.status === 401) {
          throw new Error("User is not logged in");
        } else if (error.response.status === 422) {
          throw new Error(error.response.data.message);
        }

        throw error;
      });
  }
}

export async function inactivateDashboards(dashboardIds) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };

    return await axios
      .post(
        `${ApiRootConstants.dashboardApiRoot}api/Dashboard/InactivateDashboards`,
        dashboardIds,
        options
      )
      .catch((error) => {
        console.error(error);
        if (error.response.status === 401) {
          throw new Error("User is not logged in");
        } else if (error.response.status === 422) {
          throw new Error(error.response.data.message);
        }

        throw error;
      });
  }
}

export async function deleteUserFromAllDashboards(userId) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };

    return await axios
      .post(
        `${ApiRootConstants.dashboardApiRoot}api/Dashboard/DeleteUserFromAllDashboards/${userId}`,
        null,
        options
      )
      .catch((error) => {
        console.error(error);
        if (error.response.status === 401) {
          throw new Error("User is not logged in");
        } else if (error.response.status === 422) {
          throw new Error(error.response.data.message);
        }

        throw error;
      });
  }
}
